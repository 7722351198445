<template>
  <div class="content-wrapper">
    <sign-in
      :visible="isSignIn"
      @is-sign-in-active="isSignIn = false"
    />
    <div class="header other-pages">
      <v-container>
        <!-- <button @click="pay">
          Pay Now
        </button> -->

        <div class="divider-top" />

        <v-row class="cart-container-row">
          <v-col
            md="5"
            order-md="2"
            class="cart-order"
          >
            <!----------------------------My Order Section ------------------------>

            <myOrder
              ref="myOrderRef"
              :checkout-button="false"
              sm="12"
            />
          </v-col>

          <v-col
            cols="12"
            md="7"
            order-md="1"
          >
            <div class="cart-info-card">
              <orderInfo
                ref="orderInfoRef"
                :order-info-details="orderInfoDetails"
                sm="12"
              />
              <!-- <div class="validate-filds">
                <v-text-field
                  v-model="coupon"
                  placeholder="Have a Discount Code?"
                  outlined
                />
                <v-btn
                  class="theme-btn"
                  @click="applyCoupon"
                >
                  VALIDATE
                </v-btn>
              </div> -->
              <div class="cart-btnbar">
                <v-btn
                  class="theme-btn"
                  @click="makePayment()"
                >
                  Place Order
                </v-btn>
              </div>
              <div class="cart-formbtm-txt">
                <h4>By placing this order your accept the:</h4>
                <ul>
                  <li><a
                    href="/terms_and_condition.pdf"
                    target="_blank"
                  >Terms and Conditions</a> and SLA of CBE</li>
                </ul>
                <h5>
                  You agree to be remembered in this device and to receive
                  money-off coupons and exclusive offers. Review Settings
                </h5>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <v-dialog
      v-model="addressDialog"
      width="500"
    >
      <v-container class="grey lighten-5">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="address.first_name"
              label="First name"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="address.last_name"
              label="Last name"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="address.email"
              label="E-mail"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.mobile"
              label="Mobile"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.address1"
              label="Address1"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.address2"
              label="Address2"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.city"
              label="Town/City"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.state"
              label="State"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address.pincode"
              label="Pincode"
            />
          </v-col>
        </v-row>
        <v-btn
          class="button-add-address"
          @click="saveAddress"
        >
          Save
        </v-btn>
      </v-container>
    </v-dialog> -->

    <v-dialog
      v-model="successOrder"
      width="600"
    >
      <v-container class="grey lighten-5">
        <h4 class="text-center">
          Thank You!
        </h4>

        <p class="text-center pt-10">
          Thank you for your order. We’ll notify you the status of your order.
        </p>

        <v-btn
          class="button-add-address"
          :style="{ left: '50%', transform: 'translateX(-50%)' }"
          @click="navigateToHome"
        >
          Continue
        </v-btn>
      </v-container>
    </v-dialog>

    <v-row justify="center">
      <!-- <v-btn
        color="primary"
        dark
        @click.stop="paymentDialog = true"
      >
        Open Dialog
      </v-btn> -->
      <v-dialog
        v-model="paymentDialog"
        max-width="700"
        open-delay="10"
      >
        <v-card>
          <v-progress-circular
            v-if="loadingStripeElement"
            indeterminate
            color="amber"
            class="loader-payment"
          />
          <div class="wrapper-payment">
            <v-card-title class="text-h5">
              Payment
            </v-card-title>
            <v-card-text>
              <stripe-element-payment
                v-if="elementsOptions.clientSecret"
                ref="paymentRef"
                :stripe-account="stripeAccount"
                pk="pk_test_51N84G7LxtY9TvhJkg8MvhMSo9H5nBruGbTXOkQTdVpsj7kjd0P884ZpP2nsx0VsgkjPMQnNZoh2Z5vM9jZdhwHwJ00AoWFLIBs"
                :elements-options="elementsOptions"
                :confirm-params="confirmParams"
                :element-style="elementStyle"
                @hook:mounted="test = true"
                @element-ready="elementReady()"
              />

              <div class="paynow-btnbar">
                <v-btn
                  class="paynow-btn"
                  color="primary"
                  @click="pay(paymentRef)"
                >
                  Pay Now {{ $store.state['outlet'].outletInfo.currency_type }}
                  {{ totalAmount() > 0 ? totalAmount().toFixed(2) : '' }}
                </v-btn>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import SignIn from '../components/SignIn.vue'
import useSignIn from '../components/useSignIn'
import { userService } from '../api/userService'
import { useRouter } from '../utils/utils'
import myOrder from '../components/MyOrders.vue'
import orderInfo from '../components/OrderInfo.vue'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    StripeElementPayment,
    myOrder,
    orderInfo,
    SignIn,
  },

  setup() {
    const elementsOptions = ref({
      appearance: {
        rules: {
          '.Input::placeholder': {
            fontSize: '0',
          },
        },
      }, // appearance options
      card: {
        placeholder: '', // This removes the placeholder
      },
    })

    const elementStyle = ref({
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: '500',
          fontSize: '32px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    })
    const paymentIntent = ref(false)

    const myOrderRef = ref(null)

    // onMounted(generatePaymentIntent) // on `mounted` call `getUserRepositories`

    const { cartItems } = store.state.cart

    // const token = ref(null)

    const paymentRef = ref(null)

    const e6 = ref(1)

    const orderType = ref('Pickup')

    const addressDialog = ref(false)

    const deliveryAddress = ref(null)

    const availableDayhoices = ref(['Today'])

    const availableTimeChoices = ref([])

    const successOrder = ref(false)

    const { router } = useRouter()

    const outletHours = ref(null)

    const selectedDay = ref('Today')

    const selectedTime = ref(null)

    const paymentDialog = ref(false)

    const paymentLoader = ref(true)

    const test = ref(false)

    const stripeAccount = ref(null)

    const orderInfoDetails = ref(null)

    const orderInfoRef = ref(null)

    const loadingStripeElement = ref(true)

    const coupon = ref('')

    const storeSettings = store.state.outlet.outletInfo.online_store_settings.store

    orderInfoDetails.value = store.state.user.orderDetails

    watch(store.state.user, newState => {
      orderInfoDetails.value = newState.orderDetails
      orderInfoRef.value.orderData = newState.activeUser.address
        ? newState.activeUser.address[0]
        : orderInfoRef.value.orderData.value
    })

    const returnUrl = `${window.location.origin}/${router.currentRoute.params.name}/store/${router.currentRoute.params.id}/payment-sucess`

    const confirmParams = ref({
      return_url: returnUrl,
      appearance: {}, // appearance options
    })
    const lineItems = ref([
      {
        price: 'price_1JkTOhG4BLFb3Dxk9KeHdQ5L', // The id of the one-time price you created in your Stripe dashboard
        quantity: 5.5 * 100,
      },
    ])

    const address = ref({})

    const { isSignIn } = useSignIn()

    // const defaultAddress = {
    //   first_name: '',
    //   last_name: '',
    //   email: '',
    //   mobile: '',
    //   address: '',
    //   address1: '',
    //   address2: '',
    //   city: '',
    //   state: '',
    //   pincode: '',
    // }

    const saveAddress = () => {
      const newData = store.state.user.activeUser.address
        ? JSON.parse(JSON.stringify(store.state.user.activeUser.address))
        : []
      newData.push(address.value)
      const data = { address: newData }
      userService.updateAddress(data).then(res => {
        store.state.user.activeUser.address = res.data
        addressDialog.value = false
      })
    }

    const totalAmount = () => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      return total
    }

    const weekday = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ][new Date().getDay()]

    const formatTime = (time, hourFormat24) => moment(time, 'HHmm').format(hourFormat24 ? 'HH:mm' : 'hh:mm A')

    const calculate = () => {
      // const a = []
      const intervalValue = 15

      for (
        let i = 0;
        i < outletHours.value[weekday].collection.length;
        i += 1
      ) {
        const { open } = outletHours.value[weekday].collection[i]
        const { close } = outletHours.value[weekday].collection[i]

        // const openHour = `${open.substring(0, 2)}:${open.substring(2, open.length)}`
        // const closeHour = `${close.substring(0, 2)}:${close.substring(2, close.length)}`
        // let startDate = new Date(`7/20/2021 ${openHour}`)
        // const endDate = new Date(`7/20/2021 ${closeHour}`)

        const openTime = moment(open, 'HHmm').format('HH:mm')

        const closeTime = moment(close, 'HHmm').format('HH:mm')

        let startDate = new Date()
        startDate.setHours(openTime.split(':')[0])
        startDate.setMinutes(openTime.split(':')[1])

        const endDate = new Date()
        endDate.setHours(closeTime.split(':')[0])
        endDate.setMinutes(closeTime.split(':')[1])

        const offset = intervalValue * 1000 * 60
        do {
          startDate = new Date(startDate.getTime() + offset)
          const disabled = startDate.getTime() < new Date().getTime() + offset

          if (startDate < endDate) {
            availableTimeChoices.value.push({
              time: `${`0${startDate.getHours()}`.slice(
                -2,
              )}:${`0${startDate.getMinutes()}`.slice(-2)}`,
              disabled,
            })
          }
        } while (startDate < endDate)
      }
    }

    userService.getStoreDetails().then(res => {
      if (res.data) {
        outletHours.value = res.data.online_business_hours

        calculate()
      }
    })

    const elementReady = () => {
      // console.log('element ready', state)
    }

    const applyCoupon = () => {
      const body = {
        outlet_id: router.currentRoute.params.id,
        coupon: coupon.value,
      }
      store.dispatch('cart/applyCoupon', body).then(res => {
        myOrderRef.value.discountAmount = (myOrderRef.value.totalAmount * res.discount_percentage) / 100
      })
    }

    // const handleResult = () => {
    // console.log(data)
    // alert(JSON.stringify(res));
    // eslint-disable-next-line no-use-before-define
    //   pay()
    // }

    // const handleSuccessResult = () => {
    //   placeOrder()
    // }

    // const cashier = window.com.myriadpayments.api.cashier()
    // cashier.init({
    //   baseUrl:
    //     'https://cashierui-apiuat.test.boipapaymentgateway.com/ui/cashier',
    // })

    // const pay = () => {
    //   cashier.show({
    //     containerId: 'ipgCashierDiv',
    //     merchantId: '915101',
    //     token: token.value,
    //     successCallback: handleSuccessResult,
    //     failureCallback: handleResult,
    //     cancelCallback: handleResult,
    //     country: 'IE',
    //     courency: 'EURO',
    //   })
    // }

    // store.dispatch('user/getBoiToken', totalAmount() + 5).then(res => {
    //   token.value = res.token
    //   pay()
    // })
    const generatePaymentIntent = () => {
      // loadingStripeElement.value = true
      if (!store.state.user.activeUser.address) {
        saveAddress()
      }
      const data = { amount: totalAmount() }
      store.dispatch('cart/createPaymentIntent', data).then(resp => {
        paymentDialog.value = true
        elementsOptions.value.clientSecret = resp.client_secret
        stripeAccount.value = resp.stripe_account_no
        // loadingStripeElement.value = false
        // paymentIntent.value = true
      })
    }

    const createPayment = () => {
      const resp = orderInfoRef.value.$refs.form.validate()
      if (resp) {
        generatePaymentIntent()
      }
    }

    const pay = reference => {
      reference.submit()
    }
    const clearCardField = () => {
      console.log(paymentRef)
      paymentRef.value.clear()

      // Clear the card field using Stripe's clear() method
      if (paymentRef && paymentRef.value.clear) {
        paymentRef.value.clear()
      }
    }
    const navigateToHome = () => {
      router.push({ name: 'home' })
    }

    const makePayment = () => {
      let reqOrderType = ''
      switch (orderInfoRef.value.orderType) {
        case 'dinein':
          // code block
          reqOrderType = 'Dine In'
          break
        case 'collection':
          reqOrderType = 'Collection'
          // code block
          break
        default:
        // code block
      }
      const orderData = {
        paymentMethod: orderInfoRef.value.paymentMethod,
        discountAmt: myOrderRef.value.discountAmount,
        pickUpTime: orderInfoRef.value.pickupTime,
        instructions: orderInfoRef.value.orderDetails.instructions,
        orderType: reqOrderType,
        table_no: orderInfoRef.value.orderDetails.table_no,
        guest_count: orderInfoRef.value.orderDetails.guest_count,
        phone: orderInfoRef.value.orderAddressInfo.ordersMobile,
        customer_name: orderInfoRef.value.orderAddressInfo.customerName,
      }
      localStorage.setItem('orderInfo', JSON.stringify(orderData))

      if (
        store.state.user.isLoggedIn
        || !storeSettings[orderInfoRef.value.orderType].send_otp
      ) {
        if (orderInfoRef.value.paymentMethod === 'card') {
          createPayment()
        } else {
          const resp = orderInfoRef.value.$refs.form.validate()
          if (resp) {
            const redirectUrl = `${returnUrl}?redirect_status=succeeded`
            window.location.assign(redirectUrl)
          }
        }
      } else {
        isSignIn.value = true
      }
    }

    return {
      availableTimeChoices,
      navigateToHome,
      formatTime,
      successOrder,
      totalAmount,
      availableDayhoices,
      selectedDay,
      selectedTime,
      cartItems,
      orderType,
      addressDialog,
      saveAddress,
      address,
      required,
      deliveryAddress,
      // placeOrder,
      weekday,
      // pay,
      e6,
      isSignIn,
      lineItems,
      elementsOptions,
      confirmParams,
      paymentIntent,
      paymentRef,
      paymentDialog,
      pay,
      createPayment,
      paymentLoader,
      elementReady,
      test,
      stripeAccount,
      orderInfoDetails,
      orderInfoRef,
      makePayment,
      loadingStripeElement,
      applyCoupon,
      coupon,
      myOrderRef,
      elementStyle,
      clearCardField,
    }
  },
  methods: {
    submit() {
      this.$refs.checkoutRef.redirectToCheckout()
    },
  },
}
</script>

<style lang="scss">
.header {
  .delivery-detail {
    h2 {
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: #49e0db;
    }
    h4 {
      text-align: left;
      font-size: 18px;
      line-height: 21px;
      color: #b2b6c1;
    }
    h5 {
      text-align: left;
      font-size: 12px;
      line-height: 17px;
    }
    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      span {
        font-weight: 400;
        color: #b2b6c1;
      }
    }
  }
}
.cart-header {
  // border-bottom: 0.5px solid #bdc3c7;
  position: sticky;
  top: 0;
  z-index: 9999;
  /*background-color: #fff;*/
}
.cart {
  width: 253px;
  height: 46px;
  background-color: #1f7dbb;
  float: right;
  border-radius: 5px;

  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #f2f2f2;
  }
}

.item-card {
  .card-title {
    color: #1f7dbb;
    font-size: 18px;
    font-weight: 500;
  }
  h4 {
    color: #60616c;
    font-size: 16px;
  }
  p {
    color: #b2b6c1;
    font-size: 12px;
  }
}
.item-img {
  width: 110px;
  height: 108px;
}
// .add-to-cart-btn {
//   position: absolute;
//   right: 28px;
// }
.shoping-icon {
  color: #b2b6c1 !important;
}
.slick-slide {
  width: auto !important;
}
.ipgCashierDiv {
  width: 500px;
  height: 500px;
}

.steps-size {
  width: 100%;
  height: 400px;
}
.vue-form-wizard.vertical .wizard-navigation {
  flex-direction: column;
}

</style>
