var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("sign-in", {
        attrs: { visible: _vm.isSignIn },
        on: {
          "is-sign-in-active": function($event) {
            _vm.isSignIn = false
          }
        }
      }),
      _c(
        "div",
        { staticClass: "header other-pages" },
        [
          _c(
            "v-container",
            [
              _c("div", { staticClass: "divider-top" }),
              _c(
                "v-row",
                { staticClass: "cart-container-row" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "cart-order",
                      attrs: { md: "5", "order-md": "2" }
                    },
                    [
                      _c("myOrder", {
                        ref: "myOrderRef",
                        attrs: { "checkout-button": false, sm: "12" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "7", "order-md": "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "cart-info-card" },
                        [
                          _c("orderInfo", {
                            ref: "orderInfoRef",
                            attrs: {
                              "order-info-details": _vm.orderInfoDetails,
                              sm: "12"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "cart-btnbar" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "theme-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.makePayment()
                                    }
                                  }
                                },
                                [_vm._v(" Place Order ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "cart-formbtm-txt" }, [
                            _c("h4", [
                              _vm._v("By placing this order your accept the:")
                            ]),
                            _c("ul", [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/terms_and_condition.pdf",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Terms and Conditions")]
                                ),
                                _vm._v(" and SLA of CBE")
                              ])
                            ]),
                            _c("h5", [
                              _vm._v(
                                " You agree to be remembered in this device and to receive money-off coupons and exclusive offers. Review Settings "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.successOrder,
            callback: function($$v) {
              _vm.successOrder = $$v
            },
            expression: "successOrder"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-5" },
            [
              _c("h4", { staticClass: "text-center" }, [
                _vm._v(" Thank You! ")
              ]),
              _c("p", { staticClass: "text-center pt-10" }, [
                _vm._v(
                  " Thank you for your order. We’ll notify you the status of your order. "
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "button-add-address",
                  style: { left: "50%", transform: "translateX(-50%)" },
                  on: { click: _vm.navigateToHome }
                },
                [_vm._v(" Continue ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "700", "open-delay": "10" },
              model: {
                value: _vm.paymentDialog,
                callback: function($$v) {
                  _vm.paymentDialog = $$v
                },
                expression: "paymentDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.loadingStripeElement
                    ? _c("v-progress-circular", {
                        staticClass: "loader-payment",
                        attrs: { indeterminate: "", color: "amber" }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "wrapper-payment" },
                    [
                      _c("v-card-title", { staticClass: "text-h5" }, [
                        _vm._v(" Payment ")
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _vm.elementsOptions.clientSecret
                            ? _c("stripe-element-payment", {
                                ref: "paymentRef",
                                attrs: {
                                  "stripe-account": _vm.stripeAccount,
                                  pk:
                                    "pk_test_51N84G7LxtY9TvhJkg8MvhMSo9H5nBruGbTXOkQTdVpsj7kjd0P884ZpP2nsx0VsgkjPMQnNZoh2Z5vM9jZdhwHwJ00AoWFLIBs",
                                  "elements-options": _vm.elementsOptions,
                                  "confirm-params": _vm.confirmParams,
                                  "element-style": _vm.elementStyle
                                },
                                on: {
                                  "hook:mounted": function($event) {
                                    _vm.test = true
                                  },
                                  "element-ready": function($event) {
                                    return _vm.elementReady()
                                  }
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "paynow-btnbar" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "paynow-btn",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.pay(_vm.paymentRef)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " Pay Now " +
                                      _vm._s(
                                        _vm.$store.state["outlet"].outletInfo
                                          .currency_type
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.totalAmount() > 0
                                          ? _vm.totalAmount().toFixed(2)
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }